import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, List, ListItem, ListItemIcon } from '@material-ui/core';
// assets
import IconDashboard from "../../assets/icons/icon-dashboard.svg";
import IconTables from "../../assets/icons/icon-tables.svg";
import ArrowDown from "../../assets/icons/arrow-down.svg";
// styles
import { ListDrawerStyles } from './ListDrawer.styles';
// const
import { Movimientos, Routes, Tarjetas, Limites } from "../../consts/Routes-App";
// Recoil
import { useSetRecoilState } from "recoil";
import { routeInfo } from "../../recoilState/GlobalState";

export default function ListDrawer({ setOpen }) {
    const classes = ListDrawerStyles();
    const setRoute = useSetRecoilState(routeInfo);

    const [tarjetasOpen, setTarjetasOpen] = useState(false);
    const [movimientosOpen, setMovimientosOpen] = useState(false);
    const [limitesOpen, setLimitesOpen] = useState(false);

    const handleClick = (route) => {
        setRoute(route);
        setOpen(false);
    }

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {/* Balance */}
            {Object.values(Routes).map(route => {
                return <Link to={route.route} className={classes.link} key={route.route}>
                    <ListItem button className={classes.itemList} onClick={() => handleClick(route)}>
                        <ListItemIcon>
                            <img className={classes.iconsDrawer} src={route.route === '/' ? IconDashboard : IconTables} alt="Tables" />
                        </ListItemIcon>
                        <span className={classes.textColor}>{route.wordKey}</span>
                    </ListItem>
                </Link>
            })}
            {/* Movimientos */}
            <ListItem button className={classes.itemList} onClick={() => setMovimientosOpen(!movimientosOpen)}>
                <ListItemIcon>
                    <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                </ListItemIcon>
                <span className={classes.textColor}>Movimientos</span>
                <img src={ArrowDown} alt="arrow-down" />
            </ListItem>
            <Collapse in={movimientosOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Object.values(Movimientos).map(route => {
                        if (route.route !== '/tarjetas-coeficientes') {
                            return <Link to={route.route} key={route.route} className={classes.link}>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={() => handleClick(route)}>
                                    <span className={classes.textColor}>{route.wordKey}</span>
                                </ListItem>
                            </Link>
                        }
                        return null
                    })}
                </List>
            </Collapse>
            {/* Tarjetas */}
            <ListItem button className={classes.itemList} onClick={() => setTarjetasOpen(!tarjetasOpen)}>
                <ListItemIcon>
                    <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                </ListItemIcon>
                <span className={classes.textColor}>Tarjetas</span>
                <img src={ArrowDown} alt="arrow-down" />
            </ListItem>
            <Collapse in={tarjetasOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Object.values(Tarjetas).map(route => {
                        if (route.route !== '/tarjetasCoeficientes') {
                            return <Link to={route.route} className={classes.link} key={route.route}>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={() => handleClick(route)}>
                                    <span className={classes.textColor}>{route.wordKey}</span>
                                </ListItem>
                            </Link>
                        }
                        return null
                    })}
                </List>
            </Collapse>
            {/* Limites */}
            <ListItem button className={classes.itemList} onClick={() => setLimitesOpen(!limitesOpen)}>
                <ListItemIcon>
                    <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                </ListItemIcon>
                <span className={classes.textColor}>Limites</span>
                <img src={ArrowDown} alt="arrow-down" />
            </ListItem>
            <Collapse in={limitesOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Object.values(Limites).map(route => {
                        return <Link to={route.route} className={classes.link} key={route.route}>
                            <ListItem
                                button
                                className={classes.nested}
                                onClick={() => handleClick(route)}>
                                <span className={classes.textColor}>{route.wordKey}</span>
                            </ListItem>
                        </Link>
                    })}
                </List>
            </Collapse>
        </List>
    );
}
