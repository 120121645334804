import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// components
import Filtros from "./Filtros";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { balance as titles } from "../../consts/titulos-de-tablas";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData, transformDate } from "../../services/Local-services";
import { balanceGet } from "../../services/hooli-services/Balance/BalanceGet";
import { monedasGet } from "../../services/hooli-services/Balance/MonedasGet";
// recoil
import { useRecoilValue, useRecoilState } from 'recoil';
import { rowSelected, userCredentials } from '../../recoilState/GlobalState';

export default function Balance() {
    const [balance, setBalance] = useState([]);
    const [monedas, setMonedas] = useState([]);
    const [filtros, setFiltros] = useState({
        hasta: new Date().toISOString().substring(0, 10),
        moneda: 'ARS',
        periodo: 'M',
        documento: ''
    });
    const [rows, setRows] = useState([]);
    const [procede, setProcede] = useState('');
    const [cargando, setCargando] = useState(true);

    const [row, setRow] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    async function retrieveBalance() {
        const params = {
            "fechaHasta": filtros.hasta,
            "monedaCod": filtros.moneda,
            "periodo": filtros.periodo,
            "fechaDesde": filtros.desde ? filtros.desde : null
        }
        const payload = { ...credentials, params };
        setRows([]);
        setCargando(true);
        const data = await balanceGet(payload);
        setCargando(false);
        setBalance(data)
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0){
            setCargando(true)
            retrieveData(credentials, undefined, monedasGet, setMonedas)
            setCargando(false)
        } 
    }, [credentials]);

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveBalance()
    }, [credentials, filtros]);

    useEffect(() => {
        if (balance.length > 0) {
            titles[1] = `Saldo al ${transformDate(balance[0].balanceDesde)}`
            balance.map((item) => {
                const {
                    balanceNombre,
                    balanceAnterior,
                    balanceDebitos,
                    balanceCreditos,
                    balanceActual,
                    balanceGrupo,
                    balanceEntidadId,
                    balanceTipo
                } = item

                const balance = {
                    id: balanceNombre,
                    balanceGrupo,
                    balanceEntidadId,
                    balanceTipo,
                    [titles[0]]: balanceNombre,
                    [titles[1]]: balanceAnterior,
                    [titles[2]]: balanceDebitos,
                    [titles[3]]: balanceCreditos,
                    [titles[4]]: balanceActual,
                }

                setRows(rows => [...rows, balance]);
                return balance
            })
        }
    }, [balance])

    useEffect(() => setRow({ status: false, row: {} }), []);

    const handleClick = (procede) => setProcede(procede)

    return (
        <main>
            <TransitionModal
                filtros={filtros}
                monedas={monedas}
                procede={procede}
                row={row.row}
            />
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                monedas={monedas}
                handleClick={handleClick}
                updateList={retrieveBalance}
                cargando={cargando}
            />
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} indice={0} />}
            </div>
        </main>
    )
};