import React, { useState, useEffect } from 'react'
// assets
import reload from "../../assets/icons/actualizar.svg";
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
import iconoExportar from "../../assets/icons/exportar.png";
// components
import HeaderButton from '../../components/utils/button/HeaderButton'
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import XGridDemo from '../../components/utils/grid/Xgrid';
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import SubmitButton from '../../components/utils/button/SubmitButton';
import { FormikDateInput, FormikSelectInput } from '../../components/utils/inputs/FormikInputs';
// form
import * as yup from "yup";
import { useFormik } from "formik";
// const
import { modalResumen as titles } from '../../consts/titulos-de-tablas';
// services
import { resumenGet } from '../../services/hooli-services/Balance/ResumenGet';
// recoil 
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen } from '../../recoilState/GlobalState';
// styles
import { FiltrosStyles } from '../Filtros.styles';
import styles from './modalesBalance.module.css';
import { exportarCSV, transformDate } from '../../services/Local-services';
import { usuarioBasicosGet } from '../../services/hooli-services/Balance/UsuarioBasicosGet';

export default function ResumenModal({ props }) {
    const { filtros: initialFiltros, monedas, setOpen, row } = props;

    const [filtros, setFiltros] = useState(initialFiltros)
    const [balance, setBalance] = useState([]);
    const [balanceRows, setBalanceRows] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [secondarySelected, setSecondarySelected] = useState({ status: false, row: {} });

    const credentials = useRecoilValue(userCredentials);
    const setFormOpen = useSetRecoilState(formIsOpen);

    async function retrieveResumen() {
        const params = {
            "entidadTipoCod": row.balanceGrupo,
            "tipoCuentaCod": row.balanceGrupo === 'H' ? row.balanceTipo : null,
            "entidadId": row.balanceEntidadId,
            "fechaHasta": filtros.hasta,
            "monedaCod": filtros.moneda,
            "periodo": filtros.periodo,
            "fechaDesde": filtros.desde,
        }
        const payload = { ...credentials, params };
        setCargando(true);
        setBalanceRows([]);

        const data = await resumenGet(payload);
        setCargando(false)
        setBalance(data)
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0 && filtros) retrieveResumen()
    }, [credentials, filtros])

    useEffect(() => {
        balance.map((item) => {
            const {
                movimientoId,
                movimientoFecha,
                tipoMovimientoNombre,
                movimientoDebito,
                movmientoCredito,
                saldoParcial,
                movimientoContraparte,
                movimientoReferencia,
                movimientoCOELSAId,
                movimientoComprobante,
                movimientoAdicionalNombre
            } = item

            const balance = {
                id: movimientoId,
                [titles[0]]: movimientoFecha,
                [titles[1]]: tipoMovimientoNombre,
                [titles[2]]: movimientoDebito ? parseFloat(movimientoDebito) : movimientoDebito,
                [titles[3]]: movmientoCredito ? parseFloat(movmientoCredito) : movmientoCredito,
                [titles[4]]: saldoParcial,
                [titles[5]]: movimientoContraparte,
                [titles[6]]: movimientoReferencia,
                [titles[7]]: movimientoCOELSAId,
                [titles[8]]: movimientoComprobante,
                [titles[9]]: movimientoAdicionalNombre
            }
            setBalanceRows(rows => [...rows, balance])
            return item
        })
    }, [balance]);

    const validationSchema = yup.object({
        moneda: yup.string().required("Seleccione una opcion"),
        desde: yup.date().max(yup.ref('hasta'), 'No puede ser posterior a la segunda fecha'),
        hasta: yup.date().required("Por favor seleccione una fecha"),
        periodo: yup.string().required("Seleccione un opcion"),
    });

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const data = { moneda: { label: 'Moneda' }, desde: { label: 'Desde' }, hasta: { label: 'Hasta' } }
    const classes = FiltrosStyles();

    
    const titleCSV = balanceRows[0] ? row.balanceGrupo === 'U' ? `Resumen ${row.balanceDNI} ${row.Cuenta} - ${transformDate(balanceRows[0][titles[0]])} - ${transformDate(formik.values.hasta)}` : `Resumen ${row.balanceCUIT} ${row.Cuenta} - ${transformDate(balanceRows[0][titles[0]])} - ${transformDate(formik.values.hasta)}` : 'Resumen'

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Resumen - {row.Cuenta}</h2>
            <form className={classes.root} onSubmit={formik.handleSubmit}>
                <div className={classes.filtros}>
                    <FormikSelectInput
                        data={data}
                        name="moneda"
                        formik={formik}
                        disabled={cargando}
                        options={monedas}
                        optionKey="monedaId"
                        optionValue="monedaCod"
                        optionName="monedaCod"
                        className={classes.input}
                    />
                    <TextField
                        label="Periodo"
                        name="periodo"
                        className={classes.input}
                        select
                        SelectProps={{ native: true }}
                        margin="normal"
                        value={formik.values.periodo}
                        onChange={formik.handleChange}
                        error={formik.touched.periodo && Boolean(formik.errors.periodo)}
                        helperText={formik.touched.periodo && formik.errors.periodo}
                        InputLabelProps={{ shrink: false }}
                    >
                        <option value="M">Mes</option>
                        <option value="A">Año</option>
                        <option value="T">365</option>
                        <option value="C">Custom</option>
                    </TextField>
                    {formik.values.periodo === 'C' &&
                        <FormikDateInput
                            data={data}
                            name="desde"
                            formik={formik}
                            disabled={cargando}
                            className={classes.input}
                        />
                    }
                    <FormikDateInput
                        data={data}
                        name="hasta"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    <div className={classes.marginNormal}>
                        <SubmitButton texto="Filtrar" />
                    </div>
                </div>
                <Buttons>
                    {balance.length > 0 &&
                        <HeaderButton
                            text='Exportar como .csv'
                            icon={iconoExportar}
                            exportar={() => exportarCSV(balance, titleCSV)}
                        />
                    }
                    <HeaderButton
                        text='Actualizar pagina'
                        icon={reload}
                        updateList={retrieveResumen}
                    />
                </Buttons>
            </form>
            <div id="second-grid-container">
                {cargando ? <CircularIndeterminate /> :
                    <XGridDemo
                        rows={balanceRows}
                        titles={titles}
                        secondarySelected={secondarySelected}
                        setSecondarySelected={setSecondarySelected}
                    />
                }
            </div>
            <div className={styles.buttonContainer}>
                <Button className={styles.buttonCerrar} onClick={setOpen ? () => setOpen(false) : () => setFormOpen(false)}>
                    Cerrar
                </Button>
            </div>
        </div>
    )
};